<uni-toolbar [config]="{ title: 'Rapportoversikt' }"></uni-toolbar>

<section class="application">
    <uni-tabs
        [tabs]="mainGroups"
        [labelProperty]="'label'"
        [(activeIndex)]="activeTabIndex"
        (activeIndexChange)="addTab()"
    >
    </uni-tabs>

    <div class="searchcontainer">
        <input class="search" [formControl]="searchControl" type="search" placeholder="Søk" />
    </div>

    <section class="report-groups">
        <ng-container *ngFor="let group of mainGroups[activeTabIndex].groups">
            <section class="uni-container report-group flex-column align-center" *ngIf="!group._hidden">
                <header>{{ group.label }}</header>
                <button
                    *ngFor="let report of group.reports"
                    (click)="showReport(report)"
                    [class.filtered]="report.Filtered"
                    [tabindex]="report.Filtered ? -1 : 0"
                    [title]="report.Name"
                    class="tertiary c2a report justify-start"
                >
                    <span class="ellipsis">{{ report.Name }}</span>
                    <div class="newbadge" *ngIf="report.BizReportType">Ny</div>
                </button>
            </section>
        </ng-container>
    </section>
</section>

<router-outlet></router-outlet>
